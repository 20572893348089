import React from 'react'
import {graphql} from 'gatsby'
import Layout from '../layouts'
import Nav from '../components/nav'
import Article from '../components/contentful/article'
import Metadata from '../layouts/metadata'

const ArticleTemplate = ({data: {links, article}, location}) => (
	<Layout location={location}>
		<Metadata
			title={article.title}
			keywords=""
			description={article.body.text.excerpt}
			image={article.illustration.file.url}
		/>
		<Nav links={links} pathname={location.pathname} />
		<Article {...article} />
	</Layout>
)

export default ArticleTemplate

export const query = graphql`
	query($slug: String!) {
		links: boobLink(article: {eq: $slug}) {
			previous
			next
		}
		article: contentfulArticle(slug: {eq: $slug}) {
			title
			illustration {
				...ContentfulImageFragment
			}
			caption
			body {
				text: childMarkdownRemark {
					excerpt
					html
				}
			}
		}
	}
`
